import { createRouter, createWebHistory } from "vue-router";
import { guest, auth } from "./guards";

import IndexLayout from "../layouts/home";
import UserLayout from "../layouts/user";

const login = () => import("renusify/renus/user/login");
const law = () => import("renusify/renus/law");
/* {{place new import}} */
const base = () => import("../views/index/home");
//const startBusiness = () => import("../views/index/startBusiness");
//const categories = () => import("../views/index/categories");
const voucherCategories = () => import("../views/index/voucherCategories");
const notFound = () => import("../views/index/notFound");
//const blogs = () => import("../views/index/blogs");
//const singleBlog = () => import("../views/index/singleBlog");
//const cards = () => import("../views/index/cards");
//const cartsFree = () => import("../views/index/cartsFree");
const paymentVerify = () => import("../views/index/paymentVerify");
const settings = () => import("renusify/renus/setting");
//const aboutUs = () => import("../views/index/about_us");
const vouchers = () => import("../views/index/vouchers");
const cartsVoucher = () => import("../views/index/cartsVoucher");
const singleVoucher = () => import("../views/index/singleVoucher");
/* user panel*/
const dashboard = () => import("../views/index/user/index");
/*const tickets = () => import("renusify/renus/ticket/index_user");*/
const inviteLink = () => import("../views/index/user/inviteLink");
const joinGraph = () => import("../views/index/user/joinGraph");
const graph = () => import("../views/index/user/graph");
const graphHistory = () => import("../views/index/user/graphHistory");
const activeState = () => import("../views/index/user/activeState");
const payment = () => import("../views/index/user/payment");
const orders = () => import("../views/index/user/order");
const orders_free = () => import("../views/index/user/order_free");
const messages = () => import("../views/index/user/message");
const paymentsList = () => import("../views/index/user/payments_list");
const profile = () => import("../views/index/user/profile");
const inactive = () => import("../views/index/user/inactive");
const paymentGift = () => import("../views/index/user/payment_gift");
const voucherCash = () => import("../views/index/user/voucher_cash");

const routes = [
  {
    path: "/login/:login_first?",
    name: "login",
    component: login,
    props: true,
    beforeEnter: (to, from, next) => {
      guest(to, from, next);
    }
  },
  {
    path: "/laws",
    name: "laws",
    component: law
  },
  {
    path: "/user",
    component: UserLayout,
    beforeEnter: (to, from, next) => {
      auth(to, from, next);
    },
    children: [
      { path: "", name: "dashboard", component: dashboard },
      { path: "invite_link", name: "inviteLink", component: inviteLink },
      { path: "join_graph", name: "joinGraph", component: joinGraph },
      { path: "graph-history", name: "graphHistory", component: graphHistory },
      { path: "graph", name: "graph", component: graph },
      { path: "active_state", name: "activeState", component: activeState },
      { path: "payment", name: "payment", component: payment },
      { path: "orders", name: "orders", component: orders },
      { path: "orders_free", name: "orders_free", component: orders_free },
      { path: "messages", name: "messages", component: messages },
      { path: "payments_list", name: "paymentsList", component: paymentsList },
      { path: "profile", name: "profile", component: profile },
      { path: "inactive", name: "inactive", component: inactive },
     /* { path: "tickets", name: "tickets", component: tickets },*/
      { path: "payment_gift", name: "paymentGift", component: paymentGift },
      { path: "voucher_cash", name: "voucherCash", component: voucherCash },
      { path: "settings", name: "settings", component: settings }
    ]
  },
  {
    path: "/",
    component: IndexLayout,
    children: [
      { path: "", name: "base", component: base },
      /*{
        path: "/home/start-business",
        name: "startBusiness",
        component: startBusiness
      },
      {
        path: "/home/categories",
        name: "categories",
        component: categories
      },*/
      {
        path: "/home/voucher-categories",
        name: "voucherCategories",
        component: voucherCategories
      },
      /*{
        path: "/home/blogs/:tag?",
        name: "blogs",
        component: blogs,
        props: true
      },
      {
        path: "/home/blogs/show/:blog_id",
        name: "singleBlog",
        component: singleBlog,
        props: true
      },*/
      {
        path: "/home/payment/:status/:refId",
        name: "paymentVerify",
        component: paymentVerify,
        props: true
      },
      /*{
        path: "/home/about-us",
        name: "aboutUs",
        component: aboutUs
      },
      {
        path: "/home/cards",
        name: "cards",
        component: cards
      },
      {
        path: "/home/carts-free",
        name: "cartsFree",
        component: cartsFree
      },*/
      {
        path: "/home/vouchers/:gen?",
        name: "vouchers",
        component: vouchers,
        props: true
      },
      {
        path: "/home/cartsVoucher",
        name: "cartsVoucher",
        component: cartsVoucher
      },
      {
        path: "/home/vouchers/show/:voucher_id",
        name: "singleVoucher",
        component: singleVoucher,
        props: true
      },
      { path: "/:pathMatch(.*)*", name: "not_found", component: notFound }
    ]
  }
];
const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production"
      ? process.env.API_production_URL
      : process.env.API_URL
  ),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition["top"] });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  }
});

export default router;
